<template>
    <div class="dialog-off-canvas-main-canvas d-flex flex-column h-100"
         style="background: white;"
         data-off-canvas-main-canvas>
        <CenturionHeader></CenturionHeader>
        <main role="main">
            <a id="main-content"
               tabindex="-1"></a>
            <div class="region region-content">
                <div data-drupal-messages-fallback
                     class="hidden"></div>
                <div id="block-amexsubtheme-content"
                     class="block block-system block-system-main-block">
                    <article data-history-node-id="1072"
                             role="article"
                             about="/content/centurion"
                             class="node node--type-centurion-page node--view-mode-full">
                        <div class="node__content">
                            <div
                                 class="field field--name-field-centurion-page-sections field--type-entity-reference-revisions field--label-hidden field__items">
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                    <div class="cent-freedesign">
                                                        <div class="row">
                                                            <div
                                                                 class="clearfix text-formatted field field--name-field-free-design-text field--type-text-long field--label-hidden field__item">
                                                                <div>
                                                                    <div class="col-12 p-0">
                                                                        <div
                                                                             class="banner-cont d-flex align-items-center">
                                                                            <div
                                                                                 class="col-12 d-md-flex justify-content-around align-items-center h100">
                                                                                <div class="col-md-6">
                                                                                    <div class="leftcolumn">
                                                                                        <div class="p-5 text-styling text-color-white"
                                                                                             style="color:#fff;">
                                                                                            <div class="col-12">
                                                                                                <div
                                                                                                     class="clearfix text-formatted field field--name-field-centurion-banner-title field--type-text field--label-hidden field__item">
                                                                                                    {{ $t('Centurion')
                                                                                                    }}
                                                                                                    <br />{{
                                                                                                        $t(`Membership,
                                                                                                    Reimagined`) }}
                                                                                                </div>
                                                                                                <div
                                                                                                     class="clearfix text-formatted field field--name-field-centurion-banner-text field--type-text-long field--label-hidden field__item">
                                                                                                    <p>{{ $t(`Explore
                                                                                                        your home for
                                                                                                        the latest news,
                                                                                                        offers, and
                                                                                                        curated
                                                                                                        content`) }}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6"></div>
                                                                            </div>
                                                                            <div class="col-md-2"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- <style type="text/css"></style> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-page-title paragraph--view-mode--default cent-bg-">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column justify-content-md-center position-relative">
                                                    <div class="cent-homepage-title">
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-centurion-page-title-text field--type-text-long field--label-hidden field__item color-black">
                                                            <p>{{ $t('Discover the Key Benefits') }}
                                                                <br />
                                                                {{ $t('That Set Us Apart!') }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-page-image-with-text paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-5 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column justify-content-md-center position-relative cent-imgwithtxt-container">
                                                    <div class="cent-imgwithtxt-image">
                                                        <div
                                                             class="field field--name-field-image-with-text-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/Asset-202-100.jpg`)"
                                                                 width="499"
                                                                 height="568"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-7 mt-md-0 p-md-0 d-flex align-self-md-center flex-column color-black">
                                                    <div class="cent-imgwithtxt-text">
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-title field--type-text-long field--label-hidden field__item">
                                                            <p style="color: black;">{{ $t(`Centurion Membership: The Pinnacle of Prestige
                                                                and Luxury`) }}</p>
                                                        </div>
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-text field--type-text-long field--label-hidden field__item">
                                                            <p style="color: black;">{{ $t(`The Centurion Card is more than a card; it’s an
                                                                exclusive gateway to a world of privilege and luxury,
                                                                reserved for a select few. Crafted for distinguished
                                                                individuals like you, Centurion membership is extended
                                                                by invitation only—never through application—making it
                                                                the most coveted and exclusive card in the world. As a
                                                                Centurion Cardmember, you unlock unparalleled access to
                                                                bespoke services and exclusive beneﬁts. Welcome to the
                                                                exclusive world of Centurion, where you are empowered to
                                                                embrace every moment in true style.`) }}</p>
                                                            <p style="color: black;">{{ $t(`Click`) }}
                                                                <!-- <span @click="openPdf()"
                                                                      style="cursor: pointer; color: #016fd0; text-decoration: underline;">
                                                                    {{ $t('Here') }}
                                                                </span> -->

                                                                <a v-if="selectedLanguage == 'ar'"
                                                                   href="http://online.americanexpress.com.sa/hpsbridge/tncs/centurion/Centurion_Product_Benefits_AR.pdf"
                                                                   target="_blank">
                                                                    {{ $t('Here') }}</a>

                                                                <a v-if="selectedLanguage == 'en'"
                                                                   href="https://online.americanexpress.com.sa/hpsbridge/tncs/centurion/Centurion_Product_Benefits_EN.pdf"
                                                                   target="_blank">
                                                                    {{ $t('Here') }}</a>

                                                                <!-- &nbsp; -->
                                                                {{ $t(`HomeDiscover More`) }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-page-image-with-text paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-5 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column justify-content-md-center position-relative cent-imgwithtxt-container">
                                                    <div class="cent-imgwithtxt-image">
                                                        <div
                                                             class="field field--name-field-image-with-text-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/Asset-203-100.jpg`)"
                                                                 width="499"
                                                                 height="568"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-7 mt-md-0 p-md-0 d-flex align-self-md-center flex-column color-black">
                                                    <div class="cent-imgwithtxt-text">
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-title field--type-text-long field--label-hidden field__item">
                                                            <p style="color: black;">{{ $t(`The CENTURION Art Card ARTx CENTURION`) }}</p>
                                                        </div>
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-text field--type-text-long field--label-hidden field__item">
                                                            <p style="color: black;">{{ $t(`The Centurion ‘Art Cards’ are the fruit of the ART
                                                                X CENTURION project – an exciting and innovative set of
                                                                artistic collaborations between Centurion and two of the
                                                                world’s greatest artistic visionaries. All Centurion
                                                                Members (Primary and ‘Black’ Supplementary Metal Cards
                                                                only) on each Account will be able to choose between one
                                                                of the three Centurion Metal Card designs: the ‘Classic
                                                                Black’ and the two ‘Art Cards’.`) }}</p>
                                                            <p style="color: black;">{{ $t(`Click`) }}
                                                                <span @click="dglog = true"
                                                                      style="cursor: pointer; color: #016fd0; text-decoration: underline;"
                                                                      id="centpopupopen">{{ $t(`here`) }}</span>&nbsp;{{
                                                                        $t(`to learn more about the artists behind those
                                                                designs`) }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-page-image-with-text paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-5 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column justify-content-md-center position-relative cent-imgwithtxt-container">
                                                    <div class="cent-imgwithtxt-image">
                                                        <div
                                                             class="field field--name-field-image-with-text-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/wearable.jpg`)"
                                                                 width="499"
                                                                 height="568"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-7 mt-md-0 p-md-0 d-flex align-self-md-center flex-column color-black">
                                                    <div class="cent-imgwithtxt-text">
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-title field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`The Wearable Card`) }}</p>
                                                        </div>
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-text field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`When most people think of a ‘wearable’ card, they
                                                                imagine something ‘tech-y’ — a fitness device or an
                                                                eWatch of some sort. At Centurion, we envisioned
                                                                something different: no cords or chargers, no battery
                                                                that always seems depleted. Who better to exclusively
                                                                collaborate with than Prada? They share our passion for
                                                                art and culture, and rightfully have the reputation for
                                                                experimentation and challenging convention. But
                                                                importantly — through the PRADA x CENTURION project —
                                                                they also challenged us to envision a wearable card that
                                                                is smarter and more elegant than anything previously
                                                                imagined. The result: the Prada Centurion Wearable.
                                                                Color? Black, of course. Craftsmanship? Made in Italy,
                                                                naturally. Material? The finest Saffiano leather (a
                                                                Prada signature), because innovation can also be
                                                                luxurious.`) }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-page-image-with-text paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-5 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column justify-content-md-center position-relative cent-imgwithtxt-container">
                                                    <div class="cent-imgwithtxt-image">
                                                        <div
                                                             class="field field--name-field-image-with-text-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/Centurion-Relationship-Manager-service-2.jpg`)"
                                                                 width="1455"
                                                                 height="1455"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-7 mt-md-0 p-md-0 d-flex align-self-md-center flex-column color-black">
                                                    <div class="cent-imgwithtxt-text">
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-title field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`Unleash the Power of Personalized Service!`) }}
                                                            </p>
                                                        </div>
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-text field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`As a Centurion Card Member, you gain access to
                                                                your very own dedicated Centurion relationship manager,
                                                                an exceptional expert at your service! Whether it’s
                                                                crafting the perfect travel itinerary, securing
                                                                exclusive entertainment experiences, or meeting any of
                                                                your unique needs, your Centurion relationship manager
                                                                is here to make it happen. Experience unparalleled
                                                                support and let us elevate your lifestyle to
                                                                extraordinary heights!`) }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-page-image-with-text paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-5 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column justify-content-md-center position-relative cent-imgwithtxt-container">
                                                    <div class="cent-imgwithtxt-image">
                                                        <div
                                                             class="field field--name-field-image-with-text-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/we.png`)"
                                                                 width="517"
                                                                 height="589"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-7 mt-md-0 p-md-0 d-flex align-self-md-center flex-column color-black">
                                                    <div class="cent-imgwithtxt-text">
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-title field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t('Centurion Waiting List') }}</p>
                                                        </div>
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-text field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`As a Centurion member, you are not just part of an
                                                                exclusive club, but also a privileged ambassador.
                                                                Introduce someone to Centurion, and earn 100,000
                                                                Membership Rewards® Bonus Points when they join. If you
                                                                wish to share the wonders of Centurion with a loved one,
                                                                simply add them to our waiting list. Your endorsement
                                                                helps preserve the legacy of Centurion"s unmatched
                                                                distinction.For more information, contact us.`) }}</p>
                                                            <p class="invitebtn">
                                                                <a v-if="selectedLanguage == 'ar'"
                                                                   href="https://www.americanexpress.com.sa/cent-wlist/arabic.php"
                                                                   target="_blank">
                                                                    {{ $t('Invite Here') }}</a>

                                                                <a v-if="selectedLanguage == 'en'"
                                                                   href="https://www.americanexpress.com.sa/cent-wlist/"
                                                                   target="_blank">
                                                                    {{ $t('Invite Here') }}</a>


                                                            </p>
                                                            <p>{{ $t(`As per American Express Saudi Arabia policy, the
                                                                status of referral applications cannot be disclosed to
                                                                anyone other than the Applicant. This restriction is in
                                                                place to safeguard the integrity of our processes and
                                                                protect the privacy of all parties involved. For more
                                                                information on how American Express Saudi Arabia handles
                                                                personal data, please view our Privacy Statement`) }}
                                                                <a v-if="selectedLanguage == 'ar'"
                                                                   href="https://www.americanexpress.com.sa/ar/content/%D8%A8%D9%8A%D8%A7%D9%86-%D8%B6%D9%85%D8%A7%D9%86-%D8%A7%D9%84%D8%AE%D8%B5%D9%88%D8%B5%D9%8A%D8%A9"
                                                                   target="_blank">
                                                                    {{ $t('Here') }}
                                                                </a>
                                                                <a v-if="selectedLanguage == 'en'"
                                                                   href="https://www.americanexpress.com.sa/content/privacy-statement"
                                                                   target="_blank">
                                                                    {{ $t('Here') }}
                                                                </a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-page-image-with-text paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-5 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column justify-content-md-center position-relative cent-imgwithtxt-container">
                                                    <div class="cent-imgwithtxt-image">
                                                        <div
                                                             class="field field--name-field-image-with-text-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/Asset-206-100.jpg`)"
                                                                 width="499"
                                                                 height="568"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-7 mt-md-0 p-md-0 d-flex align-self-md-center flex-column color-black">
                                                    <div class="cent-imgwithtxt-text">
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-title field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t('Fine Hotels + Resorts Program') }}</p>
                                                        </div>
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-text field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`ElevateCenturionPrivileges`) }}</p>
                                                            <p> {{ $t(`Click`) }}
                                                                <a href="https://www.americanexpress.com/en-sa/travel/discover/"
                                                                   target="_blank">
                                                                    {{ $t('Here') }}
                                                                </a>

                                                                <!-- <a v-if="selectedLanguage == 'ar'"
                                                                   href="https://www.americanexpress.com/ar-sa/travel/discover/"
                                                                   target="_blank">
                                                                    {{ $t('Here') }}
                                                                </a> -->
                                                                {{ $t('For More Information') }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-page-image-with-text paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-5 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column justify-content-md-center position-relative cent-imgwithtxt-container">
                                                    <div class="cent-imgwithtxt-image">
                                                        <div
                                                             class="field field--name-field-image-with-text-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/Asset-207-100.jpg`)"
                                                                 width="499"
                                                                 height="568"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-7 mt-md-0 p-md-0 d-flex align-self-md-center flex-column color-black">
                                                    <div class="cent-imgwithtxt-text">
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-title field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t('The Hotel Collection Program') }}</p>
                                                        </div>
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-text field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`Explore the distinctive privileges`)
                                                                }}</p>
                                                            <p> {{ $t(`Click`) }}
                                                                <a href="https://www.americanexpress.com/en-us/travel/the-hotel-collection/"
                                                                   target="_blank">
                                                                    {{ $t('Here') }}</a>
                                                                {{ $t('For More Information') }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-page-image-with-text paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-5 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column justify-content-md-center position-relative cent-imgwithtxt-container">
                                                    <div class="cent-imgwithtxt-image">
                                                        <div
                                                             class="field field--name-field-image-with-text-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/Asset-208-100.jpg`)"
                                                                 width="499"
                                                                 height="568"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-7 mt-md-0 p-md-0 d-flex align-self-md-center flex-column color-black">
                                                    <div class="cent-imgwithtxt-text">
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-title field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`Unlock Global Airline Benefits through the
                                                                International Airline Program`) }}</p>
                                                        </div>
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-text field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`Enhance your experience from departure to arrival
                                                                with our International Airline Program. Enjoy special
                                                                discounts of up to 20%, exclusive airfares, and more
                                                                with our local and international airlines. With your
                                                                Centurion Card, we bring the world closer to you.`)
                                                            }}</p>
                                                            <p>{{ $t(`For more information and booking,`) }}
                                                                <br />{{ $t(`Please call your Centurion Relationship
                                                                Manager`) }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-page-image-with-text paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-5 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column justify-content-md-center position-relative cent-imgwithtxt-container">
                                                    <div class="cent-imgwithtxt-image">
                                                        <div
                                                             class="field field--name-field-image-with-text-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/Asset-209-100.jpg`)"
                                                                 width="499"
                                                                 height="568"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-7 mt-md-0 p-md-0 d-flex align-self-md-center flex-column color-black">
                                                    <div class="cent-imgwithtxt-text">
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-title field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t('Customizable Travel Itineraries') }} </p>
                                                        </div>
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-text field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`ExperienceTheFreedomToShape`) }} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-page-image-with-text paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-5 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column justify-content-md-center position-relative cent-imgwithtxt-container">
                                                    <div class="cent-imgwithtxt-image">
                                                        <div
                                                             class="field field--name-field-image-with-text-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/Asset-2010-100.jpg`)"
                                                                 width="499"
                                                                 height="568"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-7 mt-md-0 p-md-0 d-flex align-self-md-center flex-column color-black">
                                                    <div class="cent-imgwithtxt-text">
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-title field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t('Introducing Centurion New York™') }} </p>
                                                        </div>
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-text field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`CenturionNewYorkNewLuxury`) }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-page-image-with-text paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-5 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column justify-content-md-center position-relative cent-imgwithtxt-container">
                                                    <div class="cent-imgwithtxt-image">
                                                        <div
                                                             class="field field--name-field-image-with-text-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/Asset-2011-100.jpg`)"
                                                                 width="499"
                                                                 height="568"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-7 mt-md-0 p-md-0 d-flex align-self-md-center flex-column color-black">
                                                    <div class="cent-imgwithtxt-text">
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-title field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t('Centurion Living 2025') }} </p>
                                                        </div>
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-text field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`To embrace the art of living is to discover the
                                                                beauty, culture and diversity of the world — through
                                                                experiences curated exclusively for Centurion.`) }}</p>
                                                            <p>{{ $t(`ThisSeasonLetUsImmerseYouInSome`)
                                                                }}
                                                                <br />{{ $t(`With Centurion Living Destinations, embark
                                                                on thoughtfully curated itineraries that guide you
                                                                through the most exquisite culture, entertainment and
                                                                dining, and gain privileged access to celebrated
                                                                tastings guided by experts. We hope you will enjoy it!`)
                                                                }}
                                                            </p>
                                                            <p>{{ $t(`For more information and bookings, please call
                                                                your Centurion Relationship Manager or Click`) }}
                                                                <a href="https://www.americanexpress.com/en-gl/benefits/new-centurion/living#introAvatars "
                                                                   target="_blank">{{ $t('Here') }}</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-page-image-with-text paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-5 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column justify-content-md-center position-relative cent-imgwithtxt-container">
                                                    <div class="cent-imgwithtxt-image">
                                                        <div
                                                             class="field field--name-field-image-with-text-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/Asset-2012-100.jpg`)"
                                                                 width="499"
                                                                 height="568"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-7 mt-md-0 p-md-0 d-flex align-self-md-center flex-column color-black">
                                                    <div class="cent-imgwithtxt-text">
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-title field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t('Plan It') }} </p>
                                                        </div>
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-text field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`BuyNowPayLaterService`) }}
                                                                <br />{{ $t(`ForMoreInformation`) }}
                                                            </p>
                                                            <p>
                                                                <strong>{{ $t(`First Step:`) }}</strong> {{ $t(`Buy
                                                                what you like.`) }}
                                                            </p>
                                                            <p>
                                                                <strong>{{ $t(`Second Step:`) }}</strong> {{
                                                                    $t(`Please call your Centurion Relationship Manager or
                                                                contact us on`) }}
                                                                <a href="tel:800 122 5225">800 122 5225</a> {{
                                                                    $t(`atleast 3 working days before your statement is
                                                                generated to let our team know of your desire to pay the
                                                                purchase amount in monthly installments with Plan It™.
                                                                Our team will then set up the payment plan that suits
                                                                you best.`) }}
                                                            </p>
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <th>{{ $t(`Equal Monthly Installments`) }}</th>
                                                                        <th>{{ $t(`3-12 Months`) }}</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{{ $t(`Monthly Fees`) }}</td>
                                                                        <td>1 -1.5%</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{{ $t(`Minimum Amount`) }}</td>
                                                                        <td class="currency-all" style="min-width: 220px;">{{ $t(`$266.66 or SAR 1000`) }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{{ $t(`Initiation fee (Including VAT)`) }}
                                                                        </td>
                                                                        <td class="currency-all">{{ $t(`57.5 SAR`) }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{{ $t(`Max. Amount of Card Limit`) }}</td>
                                                                        <td>{{ $t(`100%`) }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{{ $t(`Max. Active Plans`) }}</td>
                                                                        <td>5</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-page-footer paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0 margin cent-footer-pad black-bg">
                                                <div
                                                     class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column justify-content-md-center position-relative order-last order-md-first">
                                                    <div class="cent-footer-txt">
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-footer-text field--type-text-long field--label-hidden field__item">
                                                            <div class="col-12">
                                                                <div class="row">
                                                                    <div class="col-2">
                                                                        <img alt=""
                                                                             class="img-fluid"
                                                                             :src="require(`@/assets/images/centurion/contact-us-logo.png`)" />
                                                                    </div>
                                                                    <div class="col-10 d-flex align-items-center">
                                                                        <div class="contactus">
                                                                            <strong>
                                                                                {{ $t('CONTACT US') }}</strong>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 mt-3 fs">{{ $t(`For more information
                                                                and assistance,`) }}
                                                                <br />{{ $t(`please call your
                                                                Centurion Relationship Manageror:`) }}
                                                            </div>
                                                            <div class="col-12 brd"></div>
                                                            <div class="col-12">
                                                                <div class="row">
                                                                    <div class="col-1 pr-0">
                                                                        <img alt=""
                                                                             class="img-fluid"
                                                                             :src="require(`@/assets/images/centurion/phone.png`)" />
                                                                    </div>
                                                                    <div class="col-11">
                                                                        <div class="phone">{{ $t(`From inside
                                                                            SaudiArabia:`) }}
                                                                            <a href="tel:800 122 5225">800122 5225</a>
                                                                        </div>
                                                                        <div class="phone">{{ $t(`From outside
                                                                            SaudiArabia:`) }}
                                                                            <a href="tel:(+966) 11 407 1999">(+966)
                                                                                11407 1999</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12"></div>
                                                            <div class="col-12">
                                                                <div class="row">
                                                                    <div class="col-1 pr-0">
                                                                        <img alt=""
                                                                             class="img-fluid"
                                                                             :src="require(`@/assets/images/centurion/email.png`)" />
                                                                    </div>
                                                                    <div class="col-11">
                                                                        <div class="email">{{ $t('Email:') }} <a
                                                                               href="mailto:centurion@americanexpress.com.sa">centurion@americanexpress.com.sa</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <style type="text/css"></style> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 mt-md-0 p-md-0">
                                                    <div class="cent-footer-img">
                                                        <div
                                                             class="field field--name-field-footer-image field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/cent-homepage-footer.jpg`)"
                                                                 width="525"
                                                                 height="537"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-popup-container paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row">
                                                <div class="cent-popup-text"></div>
                                            </div>
                                        </div>
                                        <!-- <div class="cent-popup-bg d-none"></div>
                                        <div class="cent-popup-cont d-none">
                                            
                                        </div> -->

                                        <v-dialog v-model="dglog">
                                            <div class="container bg-white g-md-0"
                                                 style="    max-width: 70vw;
    border-radius: 25px;
    padding: 38px;
    background: white;
    position: relative;">
                                                <div class="row justify-content-end"
                                                     style="    position: absolute;
    top: -5px;
    right: 50px;">
                                                    <div class="col-auto closebtn text-end">
                                                        <a @click="dglog = false"
                                                           id="centpopupclose">x</a>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-md-4">
                                                        <div
                                                             class="paragraph paragraph--type--centurion-popup paragraph--view-mode--default">
                                                            <div class="container g-md-0">
                                                                <div class="row g-md-0">
                                                                    <div
                                                                         class="col-12 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column justify-content-md-center position-relative">
                                                                        <div class="cent-popup">
                                                                            <div
                                                                                 class="field field--name-field-popup-image field--type-image field--label-hidden field__item">
                                                                                <img :src="require(`@/assets/images/centurion/Asset-201_1.png`)"
                                                                                     width="250"
                                                                                     height="160"
                                                                                     alt=""
                                                                                     loading="lazy"
                                                                                     typeof="foaf:Image"
                                                                                     class="img-fluid" />
                                                                            </div>
                                                                            <div
                                                                                 class="color-black clearfix text-formatted field field--name-field-popup-title field--type-text field--label-hidden field__item">
                                                                                {{ $t('The ‘Classic Black’') }}
                                                                            </div>
                                                                            <div
                                                                                 class="color-black clearfix text-formatted field field--name-field-popup-text field--type-text-long field--label-hidden field__item">
                                                                                <p>{{ $t(`More subtle and sleeker. The
                                                                                    redesign of the ‘classic black’
                                                                                    Centurion Card is inspired by the
                                                                                    ethos of “less is more”. The
                                                                                    never-ending pursuit of the modern
                                                                                    and refined.`) }}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <div
                                                             class="paragraph paragraph--type--centurion-popup paragraph--view-mode--default">
                                                            <div class="container g-md-0">
                                                                <div class="row g-md-0">
                                                                    <div
                                                                         class="col-12 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column justify-content-md-center position-relative">
                                                                        <div class="cent-popup">
                                                                            <div
                                                                                 class="field field--name-field-popup-image field--type-image field--label-hidden field__item">
                                                                                <img :src="require(`@/assets/images/centurion/rem-koolhaas.png`)"
                                                                                     width="251"
                                                                                     height="160"
                                                                                     alt=""
                                                                                     loading="lazy"
                                                                                     typeof="foaf:Image"
                                                                                     class="img-fluid" />
                                                                            </div>
                                                                            <div
                                                                                 class="color-black clearfix text-formatted field field--name-field-popup-title field--type-text field--label-hidden field__item">
                                                                                {{ $t('Rem Koolhaas X CENTURION') }}
                                                                            </div>
                                                                            <div
                                                                                 class="color-black clearfix text-formatted field field--name-field-popup-text field--type-text-long field--label-hidden field__item">
                                                                                <p>{{ $t(`Inspired from a 1982 drawing
                                                                                    whose vision is both purposeful and
                                                                                    structured with a hint of playful
                                                                                    and supreme beauty. It is without a
                                                                                    doubt Rem Koolhaas capturing
                                                                                    profound energy with sublime
                                                                                    Centurion characteristics`)
                                                                                }}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <div
                                                             class="paragraph paragraph--type--centurion-popup paragraph--view-mode--default">
                                                            <div class="container g-md-0">
                                                                <div class="row g-md-0">
                                                                    <div
                                                                         class="col-12 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column justify-content-md-center position-relative">
                                                                        <div class="cent-popup">
                                                                            <div
                                                                                 class="field field--name-field-popup-image field--type-image field--label-hidden field__item">
                                                                                <img :src="require(`@/assets/images/centurion/Asset-202_2.png`)"
                                                                                     width="251"
                                                                                     height="160"
                                                                                     alt=""
                                                                                     loading="lazy"
                                                                                     typeof="foaf:Image"
                                                                                     class="img-fluid" />
                                                                            </div>
                                                                            <div
                                                                                 class="color-black clearfix text-formatted field field--name-field-popup-title field--type-text field--label-hidden field__item">
                                                                                {{ $t('Kehinde Wiley X CENTURION') }}
                                                                            </div>
                                                                            <div
                                                                                 class="color-black clearfix text-formatted field field--name-field-popup-text field--type-text-long field--label-hidden field__item">
                                                                                <p>{{ $t(`wonderfully captures`) }}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-dialog>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </main>
        <CenturionFooter></CenturionFooter>
    </div>
</template>
<script>
import store from '@/store';
import CenturionHeader from './centurion-header.vue';
import CenturionFooter from './centurion-footer.vue';
export default {
    components: {
        CenturionHeader,
        CenturionFooter
    },
    data: () => ({
        offers: [],
        loaded: true,
        selectedLanguage: 'en',
        heading: 'Available Offers',
        loadedCount: 0,
        unsubscribe: null,
        dglog: false
    }),
    created()
    {
        this.unsubscribe = store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
                if (store.get('requestKeys/accessToken') && this.loadedCount == 0)
                {
                    this.loadedCount++;
                }
            }
        })
    },
    destroyed()
    {
        this.unsubscribe();
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
        // this.loggedOffers();
    },
    methods: {
        openPdf()
        {
            if (this.selectedLanguage == 'en')
            {
                window.open("https://online.americanexpress.com.sa/hpsbridge/tncs/centurion/Centurion_Product_Benefits_EN.pdf", "_blank");
            }
            else
            {
                window.open("http://online.americanexpress.com.sa/hpsbridge/tncs/centurion/Centurion_Product_Benefits_AR.pdf", "_blank");
            }
        }
    }
}
</script>


<style lang="scss" scoped>
@import './centurion.scss';
@import './bootstrap.css';

.banner-cont {
    background: url(../../assets/images/centurion/centurion_en-min.png) 100% 70% no-repeat;
    background-size: cover;
    min-height: 450px;
}

table {
    width: 100%;
    border: solid 1px;
    text-align: center;
}

th {
    padding: 1em;
    border: solid 1px;
}

td {
    border: solid 1px;
    padding: 0.25em 0;
}

@media only screen and (max-width: 576px) {
    table {
        display: block;
        overflow-x: scroll;
    }
}

.phone,
.email,
.fs {
    font-size: 0.9em;
}

.phone a,
.email a {
    color: #ffffff;
    text-decoration: none;
}

.phone a:hover,
.email a:hover {
    color: #ffffff;
    text-decoration: underline;
}

.pad {
    padding: 0;
}

.brd {
    border-bottom: solid 1px #fff;
    margin: 1em 0 2em 0;
    max-width: 50%;
}

.pr-0 {
    padding-right: 0;
}

.centtoctitle {
    color: #000000;
    font-size: 2em;
}

.centtoclist,
.centtoclist a,
.centtoclist a:link,
.centtoclist a:visited,
.centtoclist a:hover {
    color: #000000;
    font-size: 1em;
    line-height: 1.5em;
}

@media only screen and (max-width: 576px) {
    .centtoclist {
        margin-bottom: 1.5em;
    }
}
</style>